<template>
    <router-view/>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
	@import "assets/css/flex.css";
body  {
	padding: 0;
	margin: 0;
}
p {
	padding: 0;
	margin: 0;
}
</style>
